import isValid from 'date-fns/isValid';
import queryString from 'query-string';

import { parseDate } from '../../utils/machine-date-utils';

const getSearch = (url: string) => (url ? url.split('?')[1] : '');

export const getQueryParam = (url: string, parameter: string) => {
  const search = getSearch(url);
  const parsed = queryString.parse(search);
  const value = parsed[parameter];
  return value || null;
};

export const getDateParam = (url: string, name: string) => {
  const val = getQueryParam(url, name);
  if (!val) {
    return null;
  }

  const date = parseDate(val as string);

  if (!isValid(date)) {
    return null;
  }

  return date;
};

export const getIntParam = (url: string, name: string) => {
  const val = getQueryParam(url, name);
  if (!val) {
    return null;
  }

  const number = parseInt(val as string, 10);
  return isNaN(number) ? null : number; // eslint-disable-line no-restricted-globals
};

export const getStringParam = (url: string, name: string) => {
  const val = getQueryParam(url, name);
  if (!val) {
    return null;
  }
  return val;
};
