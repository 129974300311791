import parseISO from 'date-fns/parseISO';

export const wrapDate = (date: string | Date) => {
  if (typeof date === 'string') {
    return parseISO(date);
  }
  return date;
};

export const now = () => new Date();
