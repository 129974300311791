const MARKETS_USING_MILES = [
  'LR', // Liberia
  'MM', // Myanmar
  'UK', // United Kingdom
  'US', // United States
  'AS', // American Samoa
  'BS', // Bahamas
  'BZ', // Belize
  'VG', // British Virgin Islands
  'KY', // Cayman Islands
  'DM', // Dominica
  'FK', // Falkland Islands
  'JP', // Janpan
  'GD', // Grenada
  'GU', // Guam
  'MP', // The North Mariana Islands
  'WS', // SAMOA
  'LC', // St. Lucia
  'VC', // St. Vincent & The Grenadines
  'SH', // St. Helena
  'KN', // St. Kitts & Nevis
  'TC', // Turks & Caicos Islands
  'VI', // U.S. Virgin Islands
];

type Options = {
  milesString?: string;
  kmString?: string;
  replace?: Function;
};

const formatDistance = (
  // TODO: replace any with the correct type
  { translate }: any,
  { formatNumber }: any,
  market: string,
  distanceMeters: number,
  options: Options = {},
  extra = {},
) => {
  const {
    kmString = 'DISTANCE_KILOMETRES',
    milesString = 'DISTANCE_MILES',
    replace = (val: string) => val,
  } = options;

  if (MARKETS_USING_MILES.includes(market)) {
    const distanceMiles = (distanceMeters / 1000) * 0.621371;
    const distance = formatNumber(distanceMiles, 2);
    return translate(milesString, { distance: replace(distance), ...extra });
  }

  const distanceKm = distanceMeters / 1000;
  const distance = formatNumber(distanceKm, 2);

  return translate(kmString, { distance: replace(distance), ...extra });
};

export default formatDistance;
