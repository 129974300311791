import urlParse from 'url-parse';

import {
  getQueryParam,
  getIntParam,
} from '../../../../common/src/services/url/url';
import { ROUTES } from '../routes';

import type { I18nShape } from '../../skyscanner-application/i18n';
import type { Stay } from 'common-types/types/HotelStay';
import type { HotelFilter } from 'common-types/types/components';
import type { DestinationShape } from 'common-types/types/hotels-components/types';

type DayViewURLProps = {
  destination: DestinationShape;
  filters?: HotelFilter;
  freeCancellation?: string;
  impressionId?: string;
  stay: Stay;
  i18n: I18nShape;
};

type SearchParamsURLProps = {
  cleanliness?: any;
  destination?: { entityId: number | string };
  filters?: HotelFilter;
  freeCancellation?: any;
  impressionId?: any;
  poiId?: any;
  searchCycleId?: any;
  sort?: any;
  stay?: Stay;
  upsortHotels?: any;
  url: string;
  i18n: I18nShape;
};

export const getCurrentQueryParam = (parameter: string) => {
  if (
    typeof window !== 'undefined' &&
    window.location &&
    window.location.href
  ) {
    return getQueryParam(window.location.href, parameter);
  }
  return null;
};

export const getCurrentIntParam = (name: string) =>
  getIntParam(window.location.href, name);

const removeHashRoutesFromUrl = (url: string): string =>
  ROUTES.reduce((acc, curr) => acc.replace(curr, ''), url);

const getUpdatedSearchParamsUrl = ({
  cleanliness,
  destination,
  filters,
  freeCancellation,
  i18n,
  impressionId,
  poiId,
  searchCycleId,
  sort,
  stay,
  upsortHotels,
  url,
}: SearchParamsURLProps): string => {
  const { entityId } = destination || {};
  const { checkIn, checkOut, childrenAges, numberOfAdults, numberOfRooms } =
    stay || {};
  const urlObj = urlParse(removeHashRoutesFromUrl(url), true);
  const { query } = urlObj;
  const childrenAgesString = childrenAges && childrenAges.join(',');

  if (entityId) {
    query.entity_id = entityId;
    delete query['s-f_iplace'];
  }
  if (checkIn) {
    query.checkin = i18n.formatDate(checkIn, 'yyyy-MM-dd');
  }
  if (checkOut) {
    query.checkout = i18n.formatDate(checkOut, 'yyyy-MM-dd');
  }
  if (numberOfAdults) {
    query.adults = numberOfAdults;
  }
  if (numberOfRooms) {
    query.rooms = numberOfRooms;
  }
  if (childrenAgesString) {
    query.children_ages = childrenAgesString;
  } else {
    delete query.children_ages;
  }
  if (sort) {
    query.sort = sort;
  } else {
    delete query.sort;
  }
  if (searchCycleId) {
    query.search_cycle_id = searchCycleId;
  } else {
    delete query.search_cycle_id;
  }
  if (freeCancellation) {
    query.free_cancellation = freeCancellation;
  } else {
    delete query.free_cancellation;
  }
  if (cleanliness) {
    query.cleanliness = cleanliness;
  } else {
    delete query.cleanliness;
  }
  if (filters && Object.keys(filters).length) {
    query.filters = JSON.stringify(filters);
  } else {
    delete query.filters;
  }
  if (upsortHotels) {
    query.upsort_hotels = upsortHotels;
  } else {
    delete query.upsort_hotels;
  }
  if (poiId) {
    query.poi_id = poiId;
  } else {
    delete query.poi_id;
  }
  if (impressionId) {
    query.impression_id = impressionId;
  }

  urlObj.set('query', query);
  return urlObj.toString();
};

export const getCugOverrideFromUrl = (url: string) =>
  getQueryParam(url, 'cugOverride');

const getDayviewUrl = ({
  destination,
  filters,
  freeCancellation,
  i18n,
  impressionId,
  stay,
}: DayViewURLProps): string => {
  const baseUrl = `/hotels/search`;
  return getUpdatedSearchParamsUrl({
    url: baseUrl,
    destination,
    stay,
    freeCancellation,
    filters,
    impressionId,
    i18n,
  });
};

export default getDayviewUrl;
