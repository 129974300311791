import PropTypes from 'prop-types';

export const SearchStoreShape = PropTypes.shape({
  startInitialSearch: PropTypes.func.isRequired,
  startNewSearch: PropTypes.func.isRequired,
  onSortChanged: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
  resetFiltersAndNotSearch: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  isFilterActive: PropTypes.func.isRequired,
  getFilters: PropTypes.func.isRequired,
  getSort: PropTypes.func.isRequired,
  getSearchCycleId: PropTypes.func.isRequired,
  setRequestId: PropTypes.func.isRequired,
  getRequestId: PropTypes.func.isRequired,
  restartSearch: PropTypes.func.isRequired,
  addListener: PropTypes.func.isRequired,
  removeListener: PropTypes.func.isRequired,
  getCurrentSearch: PropTypes.func.isRequired,
  getCurrentSearchResult: PropTypes.func.isRequired,
  getCurrentSearchResultCards: PropTypes.func.isRequired,
  fetchPage: PropTypes.func.isRequired,
});

export const POIStoreShape = PropTypes.shape({
  fetchPOIsPrice: PropTypes.func.isRequired,
  getPOIsResult: PropTypes.func.isRequired,
  addListener: PropTypes.func.isRequired,
  removeListener: PropTypes.func.isRequired,
});

export const MapDataStoreShape = PropTypes.shape({
  addListener: PropTypes.func.isRequired,
  removeListener: PropTypes.func.isRequired,
  getPOISearchResults: PropTypes.func.isRequired,
  getHotelSearchResults: PropTypes.func.isRequired,
  getHotelCards: PropTypes.func.isRequired,
});

export const PricesStoreShape = PropTypes.shape({
  mount: PropTypes.func.isRequired,
  onStayChanged: PropTypes.func.isRequired,
  stay: PropTypes.func.isRequired,
  mainPriceResult: PropTypes.func.isRequired,
  otaRatesResult: PropTypes.func.isRequired,
  mostPopularRatesResult: PropTypes.func.isRequired,
  availableRoomsResult: PropTypes.func.isRequired,
  addListener: PropTypes.func.isRequired,
  removeListener: PropTypes.func.isRequired,
  searchContext: PropTypes.func.isRequired,
});

export const SimilarHotelsStoreShape = PropTypes.shape({
  mount: PropTypes.func.isRequired,
  onStayChanged: PropTypes.func.isRequired,
  stay: PropTypes.func.isRequired,
  addListener: PropTypes.func.isRequired,
  removeListener: PropTypes.func.isRequired,
  searchContext: PropTypes.func.isRequired,
});

export const MetricsShape = PropTypes.shape({
  pageLoadStarted: PropTypes.func.isRequired,
  pageLoadSuccess: PropTypes.func.isRequired,
  pageLoadFailure: PropTypes.func.isRequired,
  searchSubmitted: PropTypes.func.isRequired,
  searchClicked: PropTypes.func.isRequired,
  searchValidationFailed: PropTypes.func.isRequired,
  searchCycleStarted: PropTypes.func.isRequired,
  userSearched: PropTypes.func.isRequired,
  searchSuccess: PropTypes.func.isRequired,
  searchEmpty: PropTypes.func.isRequired,
  searchFailure: PropTypes.func.isRequired,
  searchResultSelected: PropTypes.func.isRequired,
  pricesStarted: PropTypes.func.isRequired,
  pricesSuccess: PropTypes.func.isRequired,
  pricesEmpty: PropTypes.func.isRequired,
  pricesFailure: PropTypes.func.isRequired,
  dbookPriceSelected: PropTypes.func.isRequired,
  similarHotelsSuccess: PropTypes.func.isRequired,
  similarHotelsEmpty: PropTypes.func.isRequired,
  similarHotelsFailure: PropTypes.func.isRequired,
  hotelDetailsPriceLoaded: PropTypes.func.isRequired,
  detailsMainCTAClicked: PropTypes.func.isRequired,
  detailsOtherMetaPriceClicked: PropTypes.func.isRequired,
  bookingStartFailure: PropTypes.func.isRequired,
  bookingFlowTransition: PropTypes.func.isRequired,
  stathamBookingState: PropTypes.func.isRequired,
  stathamBookingTiming: PropTypes.func.isRequired,
  preReservationAvailabilityCheck: PropTypes.func.isRequired,
  finalScreenView: PropTypes.func.isRequired,
  componentLoadFailure: PropTypes.func.isRequired,
  fastSearchSuccess: PropTypes.func.isRequired,
});

export const GoogleMapsShape = PropTypes.shape({
  apiKey: PropTypes.string.isRequired,
});

export const BackendGatewayShape = PropTypes.shape({
  startSearchUpdates: PropTypes.func.isRequired,
  startMapUpdates: PropTypes.func.isRequired,
  startPricesUpdates: PropTypes.func.isRequired,
  trimUrl: PropTypes.func.isRequired,
});

export const ReviewBadgeShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
});

export const ReviewCategoryShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
});

export const ReviewGuestTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
});

export const ReviewsShape = PropTypes.shape({
  score: PropTypes.number,
  summary: PropTypes.string,
  numberOfReviews: PropTypes.number.isRequired,
  blurb: PropTypes.string,
  goodToKnow: PropTypes.string,
  badges: PropTypes.arrayOf(ReviewBadgeShape),
  categories: PropTypes.arrayOf(ReviewCategoryShape),
  guestTypes: PropTypes.arrayOf(ReviewGuestTypes),
  popularWith: PropTypes.arrayOf(PropTypes.string),
});

export const ReviewImageShape = PropTypes.shape({
  url: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

export const ReviewReplyShape = PropTypes.shape({
  replyDate: PropTypes.string.isRequired,
  replyReview: PropTypes.string.isRequired,
  isTraveler: PropTypes.bool.isRequired,
});

export const ReviewsFilterShape = PropTypes.shape({
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
});

export const ReviewsFiltersShape = PropTypes.shape({
  guestType: PropTypes.arrayOf(ReviewsFilterShape),
  dateRange: PropTypes.arrayOf(ReviewsFilterShape),
  rating: PropTypes.arrayOf(ReviewsFilterShape),
});

export const ReviewsValidFilterItemShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  translation: PropTypes.string.isRequired,
  text: PropTypes.string,
});

export const ReviewsValidFiltersShape = PropTypes.arrayOf(
  ReviewsValidFilterItemShape,
);

export const ReviewsFiltersValuesShape = PropTypes.shape({
  guestType: PropTypes.string,
  dateRange: PropTypes.string,
  rating: PropTypes.string,
});

export const ReviewResultShape = PropTypes.shape({
  logo: PropTypes.string.isRequired,
  partner: PropTypes.string.isRequired,
  review: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(ReviewImageShape),
  guestType: PropTypes.string.isRequired,
  travelGuestType: PropTypes.string.isRequired,
  tripGuestType: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  reviewDate: PropTypes.string.isRequired,
  checkinDate: PropTypes.string.isRequired,
  translationType: PropTypes.string.isRequired,
  needTranslation: PropTypes.bool.isRequired,
  replies: PropTypes.arrayOf(ReviewReplyShape),
  reviewId: PropTypes.number.isRequired,
  locale: PropTypes.string.isRequired,
  hotelId: PropTypes.string.isRequired,
  partnerReviewId: PropTypes.string,
  title: PropTypes.string,
  userLocation: PropTypes.string,
  link: PropTypes.string,
  countryName: PropTypes.string,
});

export const ReviewsMetaShape = PropTypes.shape({
  total: PropTypes.number,
  totalAvailable: PropTypes.number,
  ctripTotal: PropTypes.number,
  ctripScore: PropTypes.number,
  ctripTotalAvailable: PropTypes.number,
  taTotal: PropTypes.number,
  taScore: PropTypes.number,
  taScoreImage: PropTypes.string,
});

export const ReviewsResultsShape = PropTypes.shape({
  reviews: PropTypes.arrayOf(ReviewResultShape),
  filters: ReviewsFiltersShape,
  meta: ReviewsMetaShape,
});

export const ReviewsStoreShape = PropTypes.shape({
  addListener: PropTypes.func.isRequired,
  removeListener: PropTypes.func.isRequired,
  fetchReviews: PropTypes.func.isRequired,
  getCurrentReviews: PropTypes.func.isRequired,
  applyFilter: PropTypes.func.isRequired,
});

export const HotelStaticStoreShape = PropTypes.shape({
  addListener: PropTypes.func.isRequired,
  removeListener: PropTypes.func.isRequired,
  fetchHotel: PropTypes.func.isRequired,
  getCurrentHotel: PropTypes.func.isRequired,
});

export const AddressLocationShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

export const AddressShape = PropTypes.shape({
  streetAddress: PropTypes.string.isRequired,
  postcode: PropTypes.string,
  city: AddressLocationShape,
  secondLevelNationAdministativeDivision: AddressLocationShape,
  firstLevelNationAdministrativeDivision: AddressLocationShape,
  nation: AddressLocationShape,
});

export const DistanceShape = PropTypes.shape({
  distanceMeters: PropTypes.number.isRequired,
  referenceEntityType: PropTypes.string,
  referenceEntityName: PropTypes.string,
});

export const DistancePOIShape = PropTypes.shape({
  distance: PropTypes.number.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
});

export const ReferenceEntityShape = PropTypes.shape({
  type: PropTypes.string,
  name: PropTypes.string,
});

export const HeroImageShape = PropTypes.shape({
  highRes: PropTypes.string.isRequired,
  lowRes: PropTypes.string,
});

export const HotelDescriptionShape = PropTypes.shape({
  content: PropTypes.string,
  translated: PropTypes.bool.isRequired,
  needTraslation: PropTypes.bool,
  locale: PropTypes.string,
});

export const AmenityShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const AmenityGroupShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  amenities: PropTypes.arrayOf(AmenityShape).isRequired,
});

export const AccommodationAmenitiesShape = PropTypes.shape({
  main: PropTypes.arrayOf(AmenityShape),
  groups: PropTypes.arrayOf(AmenityGroupShape),
});

export const HotelShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  heroImage: HeroImageShape,
  numberOfStars: PropTypes.number,
  description: HotelDescriptionShape,
  checkInTime: PropTypes.string,
  checkOutTime: PropTypes.string,
  uniqueSnippet: PropTypes.string,
  location: PropTypes.shape({
    address: AddressShape.isRequired,
    distance: DistanceShape.isRequired,
    coordinates: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }).isRequired,
  }),
  reviews: ReviewsShape,
  amenities: AccommodationAmenitiesShape,
});

export const HotelCardReviewsSummaryInfoShape = PropTypes.shape({
  taRating: PropTypes.number,
  taRatingUrl: PropTypes.string,
  reviewSummaryScore: PropTypes.number,
  reviewSummaryScoreImageUrl: PropTypes.string,
  reviewSummaryScoreDesc: PropTypes.string,
  reviewSummaryCount: PropTypes.number,
});

export const SimpleHotelShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.shape({
    altText: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
  }).isRequired,
  numberOfStars: PropTypes.number.isRequired,
  location: PropTypes.shape({
    distance: DistanceShape,
  }),
  reviews: PropTypes.shape({
    score: PropTypes.number.isRequired,
    summary: PropTypes.string.isRequired,
  }).isRequired,
  url: PropTypes.string.isRequired,
  reviewsSummaryInfo: HotelCardReviewsSummaryInfoShape,
});

export const CugPriceShape = PropTypes.shape({
  originalPrice: PropTypes.number,
  discountPercentage: PropTypes.number,
  discountPrice: PropTypes.number,
});

export const CouponPriceShape = PropTypes.shape({
  originalPrice: PropTypes.number,
  discountPercentage: PropTypes.number,
  discountPrice: PropTypes.number,
  couponCode: PropTypes.string,
  totalWithoutTax: PropTypes.number,
});

export const HotelPolicyShape = PropTypes.shape({
  policy_type: PropTypes.string,
  policy: PropTypes.arrayOf(PropTypes.string),
});

export const policyV2ContentShape = PropTypes.shape({
  content: PropTypes.string.isRequired,
  title: PropTypes.string,
  is_free: PropTypes.bool,
});

export const HotelPolicyV2Shape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(policyV2ContentShape),
});

export const PriceInfoShape = PropTypes.shape({
  loading: PropTypes.bool.isRequired,
  price: PropTypes.number,
  partnerId: PropTypes.string,
  funnelType: PropTypes.oneOf(['dbook', 'meta']),
  numberOfOffers: PropTypes.number,
  error: PropTypes.string,
  cug: CugPriceShape,
});

export const HotelCardReviewsShape = PropTypes.shape({
  score: PropTypes.number,
  summary: PropTypes.string,
  numberOfReviews: PropTypes.number.isRequired,
  mostPopularWith: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  badges: PropTypes.arrayOf(ReviewBadgeShape),
});

export const StayShape = PropTypes.shape({
  checkIn: PropTypes.instanceOf(Date),
  checkOut: PropTypes.instanceOf(Date),
  numberOfAdults: PropTypes.number,
  numberOfRooms: PropTypes.number,
  childrenAges: PropTypes.arrayOf(PropTypes.string),
});

export const InitialSearchShape = PropTypes.shape({
  entityId: PropTypes.string.isRequired,
  skyscannerNodeCode: PropTypes.string.isRequired,
  stay: StayShape.isRequired,
  top: PropTypes.number,
});

export const VideoPropShape = PropTypes.shape({
  url: PropTypes.string,
  thumbnail: PropTypes.string,
});

export const RateAmenityShape = PropTypes.shape({
  name: PropTypes.string,
  type: PropTypes.string,
  displayName: PropTypes.string,
});

export const RoomRateAmenityShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.string,
  title: PropTypes.string,
  baselineDelta: PropTypes.string.isRequired,
});

export const TaxShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.shape({
    traveller: PropTypes.number.isRequired,
  }),
});

const DBookRateProperties = {
  amenities: PropTypes.arrayOf(RoomRateAmenityShape).isRequired,
  image: PropTypes.string,
  logoLink: PropTypes.string.isRequired,
  partnerId: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  name: PropTypes.string,
  rateName: PropTypes.string,
  roomName: PropTypes.string,
  type: PropTypes.string.isRequired,
  cug: CugPriceShape,
  url: PropTypes.string.isRequired,
  taxes: PropTypes.arrayOf(TaxShape),
};

export const OTARoomRateAmenityShape = PropTypes.shape({
  key: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string.isRequired,
});

export const OTARateAmenityShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.string,
  title: PropTypes.string,
  baselineDelta: PropTypes.string.isRequired,
});

export const RoomAmenityShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const GroupAmenityShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
});

export const RateShape = PropTypes.shape({
  rateId: PropTypes.string,
  roomName: PropTypes.string,
  partnerId: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.number,
  logoLink: PropTypes.string,
  url: PropTypes.string,
  isOfficial: PropTypes.bool,
  partnerType: PropTypes.string,
  amenities: PropTypes.arrayOf(OTARateAmenityShape),
  cug: CugPriceShape,
  type: PropTypes.string,
  priceWithFees: PropTypes.number,
  roomId: PropTypes.string,
  baseRoomId: PropTypes.string,
});

export const RatesGroupedByBaseRoomIdShape = PropTypes.shape({
  baseRoomId: PropTypes.string,
  baseRoomName: PropTypes.string,
  partnerRoomNames: PropTypes.arrayOf(PropTypes.string),
  rates: PropTypes.arrayOf(RateShape),
});

export const DBookRateShape = PropTypes.shape(DBookRateProperties);

export const GroupDBookRateShape = PropTypes.shape({
  ...DBookRateProperties,
  partnerId: PropTypes.string.isRequired,
  partnerName: PropTypes.string.isRequired,
  logoLink: PropTypes.string.isRequired,
  roomId: PropTypes.string.isRequired,
});

export const GroupRateShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  amenities: PropTypes.arrayOf(GroupAmenityShape).isRequired,
  rates: PropTypes.arrayOf(RateShape).isRequired,
});

export const BenefitItemShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
});

export const RoomGroupShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  roomName: PropTypes.string.isRequired,
  logoLink: PropTypes.string.isRequired,
  partnerId: PropTypes.string.isRequired,
  partnerName: PropTypes.string.isRequired,
  amenities: PropTypes.arrayOf(RoomAmenityShape),
  rateGroups: PropTypes.arrayOf(GroupRateShape),
});

export const BedWidthShape = PropTypes.shape({
  value: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
});

export const BedShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  width: BedWidthShape.isRequired,
});

export const PhotoShape = PropTypes.shape({
  url: PropTypes.string.isRequired,
});

export const SizeShape = {
  max: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
};

export const RoomInfoShape = {
  roomId: PropTypes.string.isRequired,
  baseRoomId: PropTypes.string,
  roomName: PropTypes.string.isRequired,
  beds: PropTypes.arrayOf(BedShape),
  amenities: PropTypes.arrayOf(RoomRateAmenityShape),
  photos: PropTypes.arrayOf(PhotoShape),
  size: SizeShape,
  floors: PropTypes.string,
};

export const RoomRateShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  roomName: PropTypes.string.isRequired,
  rateName: PropTypes.string.isRequired,
  image: PropTypes.string,
  price: PropTypes.number.isRequired,
  amenities: PropTypes.arrayOf(RoomRateAmenityShape),
  url: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

export const RoomTypeShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  bedType: PropTypes.string,
  amenities: PropTypes.arrayOf(RoomRateAmenityShape),
  // eslint-disable-next-line react/forbid-prop-types
  images: PropTypes.array,
  capacity: PropTypes.number,
  size: PropTypes.string,
});

export const PoiShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  distanceMeters: PropTypes.number.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  type: PropTypes.string,
  img: PropTypes.string,
  address: PropTypes.string,
  distance: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  rating: PropTypes.number,
});

export const PoiCategoryShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  pois: PropTypes.arrayOf(PoiShape).isRequired,
});

export const NeighbourhoodShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  img: PropTypes.string,
});

export const TranslationsShape = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.node,
  PropTypes.arrayOf(PropTypes.node),
]);

export const resultShape = (valueShape: any) =>
  PropTypes.shape({
    isLoading: PropTypes.bool,
    value: valueShape,
    // eslint-disable-next-line react/forbid-prop-types
    error: PropTypes.any,
  });

export const PoisResultShape = resultShape(
  PropTypes.shape({
    attractions: PropTypes.arrayOf(PoiShape),
    restaurants: PropTypes.arrayOf(PoiShape),
  }),
);

export const RoomTypeNumBasedOnPartnerShape = PropTypes.shape({
  partnerId: PropTypes.string.isRequired,
  roomTypeNum: PropTypes.number.isRequired,
  lowestPrice: PropTypes.number.isRequired,
});

export const RateResultForNewShape = resultShape(RateShape);

export const RateResultShape = resultShape(RateShape);

export const OfficialPartnerShape = PropTypes.shape({
  websiteId: PropTypes.string.isRequired,
});

export const PriceShape = PropTypes.shape({
  amenities: PropTypes.arrayOf(OTARoomRateAmenityShape).isRequired,
  deepLink: PropTypes.string.isRequired,
  isOfficial: PropTypes.bool.isRequired,
  logoLink: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  partnerId: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  cug: CugPriceShape,
  funnelType: PropTypes.string.isRequired,
  numberOfOffers: PropTypes.number.isRequired,
});

export const allPricesInfoShape = PropTypes.shape({
  loading: PropTypes.bool.isRequired,
  prices: PropTypes.arrayOf(PriceShape),
  error: PropTypes.string,
});

export const ReviewForConfidentMsgShape = PropTypes.shape({
  ratingType: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
});

export const SinglePriceDataShape = PropTypes.shape({
  direction: PropTypes.string,
  median: PropTypes.number,
});

export const HotelCardShapeContent = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  numberOfStars: PropTypes.number,
  location: PropTypes.shape({
    distance: DistanceShape,
  }),
  reviews: HotelCardReviewsShape,
  amenities: PropTypes.arrayOf(PropTypes.string),
  priceInfo: PriceInfoShape,
  distanceInfo: DistanceShape,
  url: PropTypes.string.isRequired,
  allPricesInfo: allPricesInfoShape,
  device: PropTypes.bool,
  reviewsSummaryInfo: HotelCardReviewsSummaryInfoShape,
  city: PropTypes.string,
};

export const HotelCardShape = PropTypes.shape(HotelCardShapeContent);

export const ViewedHotelsStoreShape = PropTypes.shape({
  startInitialSearch: PropTypes.func.isRequired,
  startNewSearch: PropTypes.func.isRequired,
  getCurrentSearchResult: PropTypes.func.isRequired,
  getCurrentSearchResultCards: PropTypes.func.isRequired,
  addListener: PropTypes.func.isRequired,
  removeListener: PropTypes.func.isRequired,
});

export const CityShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
});

export const ViewDatesShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
});

export const ViewedHotelFilterShape = PropTypes.shape({
  cities: PropTypes.arrayOf(CityShape),
  viewDates: PropTypes.arrayOf(ViewDatesShape),
});

export const HotelsGroupByCityShape = PropTypes.shape({
  city: CityShape,
  hotelCards: PropTypes.arrayOf(HotelCardShape),
});

export const HotelsFilteredByAllCitiesShape = PropTypes.shape({
  viewDate: ViewDatesShape,
  hotels: PropTypes.arrayOf(HotelsGroupByCityShape),
});

export const ViewedHotelsDataShape = PropTypes.shape({
  stay: StayShape,
  filter: ViewedHotelFilterShape,
  groupedHotels: PropTypes.arrayOf(HotelsFilteredByAllCitiesShape),
  sortedHotels: PropTypes.arrayOf(HotelCardShape),
  pricePolicy: PropTypes.string,
  priceType: PropTypes.string,
  requestId: PropTypes.string,
  searchId: PropTypes.string,
  selectedChipId: PropTypes.string.isRequired,
  onSelectedChipChange: PropTypes.func,
  onStayChange: PropTypes.func,
  onClear: PropTypes.func,
});

export const DeviceShape = PropTypes.shape({
  isTablet: PropTypes.bool,
  isMobilePhone: PropTypes.bool,
  isBrowser: PropTypes.bool,
});

export const CugOverrideShape = PropTypes.arrayOf(
  PropTypes.oneOf(['flight_booked', 'flight_customer']),
);

export const UserPreferencesShape = PropTypes.shape({
  utid: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  isNewUser: PropTypes.bool,
  userPreferencesId: PropTypes.string,
});

export const UserContextShape = PropTypes.shape({
  bellboyServedBy: PropTypes.string,
  bellboySortingVersion: PropTypes.string,
  cugOverride: CugOverrideShape,
  device: DeviceShape,
  flightsCugEnabled: PropTypes.bool,
  ipAddress: PropTypes.string,
  locale: PropTypes.string,
  searchResultCacheEnable: PropTypes.bool,
  searchCycleId: PropTypes.number,
  userPreferences: UserPreferencesShape,
  pageType: PropTypes.string,
});

export const ImageGalleryNodeItemShape = PropTypes.shape({
  node: PropTypes.node,
  category: PropTypes.string,
  thumbnail: PropTypes.string.isRequired,
});

export const ImageShape = PropTypes.shape({
  url: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['bedroom', 'interior', 'outlook', 'bathroom', 'other'])
    .isRequired,
});

export const ImageGalleryItemShape = PropTypes.shape({
  original: PropTypes.string,
  thumbnail: PropTypes.string,
  thumbnailClass: PropTypes.string,
  type: PropTypes.oneOf(['bedroom', 'interior', 'outlook', 'bathroom', 'other'])
    .isRequired,
});

export const VideoShape = PropTypes.shape({
  thumbnail: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

export const MediaGalleryCategoryShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
});

export const BaseRoomTypeShape = PropTypes.shape({
  baseRoomId: PropTypes.string.isRequired,
  baseRoomType: PropTypes.string.isRequired,
  baseRoomName: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  lowestRate: PropTypes.number,
});

export const MediaGalleryItemShape = PropTypes.shape({
  sortId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  poster: PropTypes.string,
  category: PropTypes.string.isRequired,
});

export const MediaGalleryShape = PropTypes.shape({
  items: PropTypes.arrayOf(MediaGalleryItemShape),
  categories: PropTypes.arrayOf(MediaGalleryCategoryShape),
});

export const PositionShape = PropTypes.shape({
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
});

export const ProgressBarShape = PropTypes.shape({
  visible: PropTypes.bool,
  progress: PropTypes.number,
});

export const SearchStateShape = PropTypes.shape({
  loading: PropTypes.bool,
  progressBar: ProgressBarShape,
  spinner: PropTypes.shape({
    visible: PropTypes.bool,
  }),
  hotelCards: PropTypes.arrayOf(HotelCardShape),
  pricePolicy: PropTypes.string,
  error: PropTypes.shape({
    type: PropTypes.string,
  }),
});

export const SearchResultShape = resultShape(SearchStateShape);

export const RecommendPriceInfoShape = PropTypes.shape({
  price: PropTypes.number,
  partnerId: PropTypes.string,
  funnelType: PropTypes.oneOf(['dbook', 'meta']),
});

export const RecommendHotelCardShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  numberOfStars: PropTypes.number,
  numberOfReviews: PropTypes.number,
  reviewScore: PropTypes.number,
  reviewSummary: PropTypes.string,
  location: PositionShape,
  priceInfo: RecommendPriceInfoShape.isRequired,
  distanceInfo: DistanceShape,
  cug: CugPriceShape,
  mostPopularWith: PropTypes.string,
  reviewsSummaryInfo: HotelCardReviewsSummaryInfoShape,
});

export const HotelRecommendResultShape = PropTypes.shape({
  recommendHotelsCards: PropTypes.arrayOf(RecommendHotelCardShape),
  destinationName: PropTypes.string,
  searchCycleId: PropTypes.number,
});

export const RecommendResultShape = PropTypes.shape({
  recommendGuestResult: HotelRecommendResultShape,
  recommendGeneralResult: HotelRecommendResultShape,
  data: HotelRecommendResultShape,
  selectedRecommendChip: PropTypes.string,
  hideRecommend: PropTypes.bool,
  onSelectRecommendChip: PropTypes.func,
  onHideRecommend: PropTypes.func,
});

export const RecommendChipsShape = PropTypes.arrayOf(PropTypes.string);

export const InspirationCardShape = PropTypes.shape({
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nation: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
});

export const DestinationShape = PropTypes.shape({
  entityId: PropTypes.string,
  entity: PropTypes.string.isRequired,
  city: PropTypes.string,
  adm1: PropTypes.string,
  adm2: PropTypes.string,
  nation: PropTypes.string,
  type: PropTypes.string,
});

export const CalendarPriceDataShape = PropTypes.objectOf(SinglePriceDataShape);

export const RecentSearchShape = PropTypes.shape({
  destination: DestinationShape,
  checkInDate: PropTypes.instanceOf(Date),
  checkOutDate: PropTypes.instanceOf(Date),
  adults: PropTypes.number,
  childrenAges: PropTypes.arrayOf(PropTypes.string),
});

export const AutosuggestSuggestionShape = PropTypes.shape({
  type: PropTypes.string.isRequired,
  entityId: PropTypes.number.isRequired,
  entity: PropTypes.string.isRequired,
  city: PropTypes.string,
  adm2: PropTypes.string,
  adm1: PropTypes.string,
  nation: PropTypes.string,
  label: PropTypes.string,
});

export const PopularDestinationShape = PropTypes.shape({
  popularDestinations: PropTypes.arrayOf(AutosuggestSuggestionShape),
});

export const BoundsShape = PropTypes.shape({
  north: PropTypes.number.isRequired,
  west: PropTypes.number.isRequired,
  south: PropTypes.number.isRequired,
  east: PropTypes.number.isRequired,
});

export const CoordinatesShape = PropTypes.shape({
  longitude: PropTypes.number.isRequired,
  latitude: PropTypes.number.isRequired,
});

export const MarkerShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
});

export const LoggerShape = PropTypes.shape({
  info: PropTypes.func.isRequired,
  warn: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
});

export const HomePageCopyShape = PropTypes.shape({
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
});

export const ScoreSummary = PropTypes.oneOf([
  'rating_average',
  'rating_below_average',
  'rating_excellent',
  'rating_good',
  'rating_none',
  'rating_outstanding',
  'rating_satisfactory',
  'rating_very_good',
  'rating_with_honors',
]);

export const LinkShape = PropTypes.shape({
  href: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
});

const CrossLinksSectionShape = PropTypes.shape({
  title: TranslationsShape.isRequired,
  links: PropTypes.arrayOf(LinkShape),
});

export const CrossLinksShapeContent = {
  sections: PropTypes.arrayOf(CrossLinksSectionShape),
};

export const CrossLinksShape = PropTypes.arrayOf(CrossLinksSectionShape);

export const FilterCheckboxShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
});

export const FilterSliderShape = PropTypes.shape({
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  currentMin: PropTypes.number.isRequired,
  currentMax: PropTypes.number.isRequired,
});

export const FilterShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  backendKey: PropTypes.string,
  type: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(FilterCheckboxShape),
    FilterSliderShape,
  ]),
  node: PropTypes.node,
});

export const ToggleFilterShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  backendKey: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  optionId: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(FilterCheckboxShape),
    FilterSliderShape,
  ]).isRequired,
  node: PropTypes.node,
});

const FAQItemShape = PropTypes.shape({
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
});

export const FAQsItemsShape = PropTypes.arrayOf(FAQItemShape);

export const DetailsPageStateShape = PropTypes.shape({
  addListener: PropTypes.func.isRequired,
  removeListener: PropTypes.func.isRequired,
  galleryIndex: PropTypes.func.isRequired,
  isMapPortalOpen: PropTypes.func.isRequired,
  openGallery: PropTypes.func.isRequired,
  closeGallery: PropTypes.func.isRequired,
  openMapPortal: PropTypes.func.isRequired,
  closeMapPortal: PropTypes.func.isRequired,
});

export const FeatureTestsShape = PropTypes.shape({
  variant: PropTypes.func.isRequired,
});

export const ConfigurationShape = PropTypes.shape({
  seoRenderUnselectedItems: PropTypes.bool.isRequired,
  brazilBillingAddressEnabled: PropTypes.bool.isRequired,
  couponEnabledOnHotel: PropTypes.bool.isRequired,
  supportPriceWithBestSorting: PropTypes.bool.isRequired,
  dvMapMannualSearch: PropTypes.bool.isRequired,
  enableCalendarWithPrice: PropTypes.bool.isRequired,
  viewedHotelsEnable: PropTypes.bool.isRequired,
  viewedHotelsDesktopEnable: PropTypes.bool.isRequired,
  poiMarkerEnable: PropTypes.bool.isRequired,
  secretDealsEnabled: PropTypes.bool.isRequired,
});

export const HotelPinShape = PropTypes.shape({
  price: PropTypes.number.isRequired,
  position: PositionShape.isRequired,
});

export const RoomAttributePropTypes = {
  title: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  subtitle: PropTypes.string,
  displayMode: PropTypes.oneOf(['', 'positive', 'neutral', 'warning']),
};

export const RoomAttributeShape = PropTypes.shape(RoomAttributePropTypes);

export const RoomAttributesShape = PropTypes.arrayOf(RoomAttributeShape);

export const RoomRateNewShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  attributes: RoomAttributesShape.isRequired,
  cost: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

export const IconListItemShape = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export const IconListItemsShape = PropTypes.arrayOf(
  PropTypes.shape(IconListItemShape).isRequired,
);

export const MealPlanTableShape = {
  headers: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ),
};

export const ComponentChildrenShape = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

export const PriceBucketShape = PropTypes.shape({
  count: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  min_price: PropTypes.number.isRequired,
  max_price: PropTypes.number,
});

export const RateTranslationsShape = PropTypes.shape({
  nameState: PropTypes.string,
  name: PropTypes.string,
  descriptionState: PropTypes.string,
  description: PropTypes.string,
});

export const SearchFiltersShape = PropTypes.objectOf(
  PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number, // Special case for price.price_min/max
      PropTypes.bool,
    ]),
  ),
);

export const PriceFilterTypesShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
);

export const PriceFilterCustomizedShape = PropTypes.shape({
  price_min: PropTypes.string.isRequired,
  price_max: PropTypes.string.isRequired,
});

export const PriceFilterOptionsShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    min_price: PropTypes.number.isRequired,
    max_price: PropTypes.number,
    count: PropTypes.number.isRequired,
    checked: PropTypes.bool.isRequired,
  }),
);

export const PriceFilterShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PriceFilterOptionsShape.isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  customized: PriceFilterCustomizedShape.isRequired,
});

export const CommonFilterOptionsShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    enabled: PropTypes.bool,
    count: PropTypes.number.isRequired,
    checked: PropTypes.bool.isRequired,
  }),
);

export const CommonFilterShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: CommonFilterOptionsShape.isRequired,
});

export const DistanceFilterItemShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  image: PropTypes.string,
  coordinate: PropTypes.arrayOf(PropTypes.number),
  subType: PropTypes.string.isRequired,
});

export const DistanceFilterOptionItemShape = PropTypes.arrayOf(
  DistanceFilterItemShape,
);

export const DistanceFilterOptionShape = PropTypes.shape({
  poiType: PropTypes.string.isRequired,
  poiName: PropTypes.string.isRequired,
  poiOptions: DistanceFilterOptionItemShape,
});

export const DistanceFilterOptionsShape = PropTypes.arrayOf(
  DistanceFilterOptionShape,
);

export const DistanceFilterShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: DistanceFilterOptionsShape,
  type: PropTypes.string,
});

export const POICardOptionShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  category: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  price: PropTypes.number,
});

export const POICardOptionsShape = PropTypes.arrayOf(POICardOptionShape);

export const FiltersShape = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PriceFilterShape,
    CommonFilterShape,
    DistanceFilterShape,
  ]),
);

export const RoomSizeShape = PropTypes.shape({
  min: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  max: PropTypes.number,
});

export const RoomBedsShape = PropTypes.arrayOf(
  PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    width: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.string,
    }),
  }),
);

export const DBookRoomRateShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  amenities: PropTypes.arrayOf(RoomRateAmenityShape).isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  isTranslated: PropTypes.bool.isRequired,
  cug: CugPriceShape,
});

export const DBookRoomTypeShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  roomName: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  rates: PropTypes.arrayOf(DBookRoomRateShape).isRequired,
  amenities: PropTypes.arrayOf(AmenityShape).isRequired,
  isTranslated: PropTypes.bool.isRequired,
  floors: PropTypes.string,
  beds: RoomBedsShape,
  size: RoomSizeShape,
});

export const DBookPartnerShape = PropTypes.shape({
  partnerId: PropTypes.string.isRequired,
  partnerName: PropTypes.string.isRequired,
  logoLink: PropTypes.string.isRequired,
  roomTypes: PropTypes.arrayOf(DBookRoomTypeShape),
});

export const DBookFilterItem = PropTypes.shape({
  key: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
});

export const DBookFilterOption = PropTypes.shape({
  value: PropTypes.string.isRequired,
  displayValue: PropTypes.string.isRequired,
});

export const DBookFilters = PropTypes.shape({
  sortedFilters: PropTypes.arrayOf(DBookFilterItem).isRequired,
  options: PropTypes.shape({
    bedType: PropTypes.arrayOf(DBookFilterOption),
    cancellationPolicy: PropTypes.arrayOf(DBookFilterOption),
  }),
});

export const SelectedFiltersShape = PropTypes.shape({
  bedType: PropTypes.arrayOf(PropTypes.string),
  mealPlan: PropTypes.bool,
  cancellationPolicy: PropTypes.bool,
  paymentPolicy: PropTypes.bool,
});

export const DBookPartnersShape = PropTypes.arrayOf(DBookPartnerShape);

export const DBookPartnersResultShape = resultShape(DBookPartnersShape);

export const SpecialRatesShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
);

export const NavigationItems = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    anchorName: PropTypes.string.isRequired,
    isShow: PropTypes.bool.isRequired,
    text: PropTypes.string,
    mobile: PropTypes.bool,
  }),
);

export const TimeLineShape = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    isFinished: PropTypes.bool.isRequired,
  }),
);

export const ReviewSummaryCategoryShape = PropTypes.shape({
  type: PropTypes.string,
  name: PropTypes.string,
  score: PropTypes.number,
  image: PropTypes.string,
  description: PropTypes.string,
  highlights: PropTypes.string,
});

export const ReviewsSummaryShape = PropTypes.shape({
  score: PropTypes.number,
  imageUrl: PropTypes.string,
  description: PropTypes.string,
  count: PropTypes.number,
  highlights: PropTypes.string,
  categories: PropTypes.arrayOf(ReviewSummaryCategoryShape),
});

export const GroupsResultShape = resultShape(
  PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      amenities: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ),
);

export const RoomFiltersShape = PropTypes.arrayOf(
  PropTypes.shape({
    roomId: PropTypes.string.isRequired,
    roomName: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
  }),
);

export const RoomFiltersResultShape = resultShape(
  PropTypes.arrayOf(RoomFiltersShape),
);

export const SortTypeShape = resultShape(
  PropTypes.shape({
    sortId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
  }),
);

export const PriceTypeShape = resultShape(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
);

export const SelectedRoomFiltersShape = PropTypes.objectOf(PropTypes.bool);

export const HotelStaticInfoShape = PropTypes.shape({
  address: AddressShape,
  reviewForConfidentMsgList: PropTypes.arrayOf(ReviewForConfidentMsgShape),
  policies: PropTypes.arrayOf(HotelPolicyV2Shape),
});

export const EntityTypeShape = PropTypes.shape({
  entityId: PropTypes.string,
  name: PropTypes.string,
  entityType: PropTypes.string,
});

export const HotelCouponOfferShape = PropTypes.shape({
  campaignId: PropTypes.string,
  offerCode: PropTypes.string,
  usageConstraint: PropTypes.shape({
    unlimited: PropTypes.bool,
    partnerWhitelists: PropTypes.arrayOf(PropTypes.string),
  }),
  discount: PropTypes.shape({
    percentageOff: PropTypes.number,
  }),
  expirationDate: PropTypes.string,
  status: PropTypes.string,
});

export const CityEntityShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
});

export const MapStoreShape = PropTypes.shape({
  addListener: PropTypes.func.isRequired,
  removeListener: PropTypes.func.isRequired,
  onRegionChange: PropTypes.func.isRequired,
  getCurrentSearchResult: PropTypes.func.isRequired,
});

export const BreadcrumbItemShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const GeneralCouponShape = PropTypes.shape({
  couponCode: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
  expirationDateTime: PropTypes.string.isRequired,
  discountPercentage: PropTypes.number.isRequired,
  discountPrice: PropTypes.number.isRequired,
  discountType: PropTypes.number.isRequired,
  originalPrice: PropTypes.number.isRequired,
});

export const MapStateShape = PropTypes.shape({
  addListener: PropTypes.func.isRequired,
  removeListener: PropTypes.func.isRequired,
  selectHotelCard: PropTypes.func.isRequired,
  getMapState: PropTypes.func.isRequired,
});

export const ResponsibleImageSizesShape = PropTypes.arrayOf(
  PropTypes.shape({
    query: PropTypes.number,
    w: PropTypes.number.isRequired,
    h: PropTypes.number.isRequired,
  }),
);

export const LocationShape = PropTypes.shape({
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
});

export const HistoryShape = PropTypes.shape({
  push: PropTypes.func.isRequired,
});

export const PoiV2Shape = PropTypes.shape({
  entityId: PropTypes.string.isRequired,
  poiName: PropTypes.string.isRequired,
  address: PropTypes.string,
  poiType: PropTypes.string.isRequired,
  linearDistance: PropTypes.number.isRequired,
  imageUrls: PropTypes.arrayOf(PropTypes.string),
  latitude: PropTypes.string.isRequired,
  longitude: PropTypes.string.isRequired,
  reviewScore: PropTypes.number,
  reviewCount: PropTypes.number,
});

export const PoiDataStateShape = PropTypes.shape({
  selectedTab: PropTypes.string,
  selectedEntityId: PropTypes.string,
  nearbyPoi: PropTypes.arrayOf(PoiV2Shape),
  transportWithoutAirport: PropTypes.arrayOf(PoiV2Shape),
  airport: PropTypes.arrayOf(PoiV2Shape),
  onPoiMarkerClick: PropTypes.func,
  onTabClick: PropTypes.func,
});

export const DateRangeShape = PropTypes.shape({
  checkIn: PropTypes.instanceOf(Date),
  checkOut: PropTypes.instanceOf(Date),
});

export const GuestsShape = PropTypes.shape({
  numberOfAdults: PropTypes.number,
  numberOfRooms: PropTypes.number,
  childrenAges: PropTypes.arrayOf(PropTypes.string),
});

export const LayoutInfoShape = PropTypes.shape({
  marginLeft: PropTypes.number,
  width: PropTypes.number,
});

export const CaretInfoShape = PropTypes.shape({
  marginLeft: PropTypes.number,
});

export const DomesticTravelPropsShape = PropTypes.shape({
  strings: PropTypes.objectOf(PropTypes.string).isRequired,
  version: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
});

export const CombinedDateShape = PropTypes.shape({
  checkIn: PropTypes.instanceOf(Date).isRequired,
  checkOut: PropTypes.instanceOf(Date).isRequired,
});

export const SectionShape = PropTypes.shape({
  testId: PropTypes.string,
  title: TranslationsShape,
  node: PropTypes.node,
  className: PropTypes.string,
});
