const HASH_REVIEWS = '#/hotel-reviews';

const HASH_AMENITIES = '#/hotel-amenities';

const HASH_GOOD_TO_KNOW = '#/good-to-know';

const HASH_IMAGE_GALLERY = '#/image-gallery';

const HASH_HOTELS_GALLERY = '#/hotels-gallery';

const HASH_MAP_PORTAL = '#/map-portal';

const HASH_HOTEL_STAYDATE = '#/hotel-staydate';

const HASH_HOTEL_STAYINFO = '#/hotel-stayinfo';

const HASH_ALL_RATES = '#/hotel-all-rates';

const HASH_OTA_RATES = '#/hotel-ota-rates';

const HASH_FULL_MAP = '#/hotel-full-map';

const HASH_SEARCH_MAP = '#/search-map';

const HASH_VIEWED_HOTELS = '#viewed-hotels';

const HASH_LOCATION_MODAL = '#destination-modal';

const HASH_HEADER_RETAIN_MODAL = '#logo-header-retain-modal';

const HASH_ROOM_CHANGE_RETAIN_MODAL = '#room-change-retain-modal';

const HASH_GUESTS_ROOMS_CHILDREN = '#guests_rooms_children';

const HASH_CHECK_IN_CHECK_OUT = '#check_in_check_out';

const HASH_FILTER_AND_SORT = '#filter_and_sort';

const HASH_RATE_DETAIL_MODAL = '#/rate_detail_modal';

const HASH_RATE_PRICE_INFO_MODAL = '#/rate_price_info_modal';

const ROUTES = [
  HASH_REVIEWS,
  HASH_AMENITIES,
  HASH_GOOD_TO_KNOW,
  HASH_IMAGE_GALLERY,
  HASH_HOTELS_GALLERY,
  HASH_MAP_PORTAL,
  HASH_HOTEL_STAYDATE,
  HASH_HOTEL_STAYINFO,
  HASH_ALL_RATES,
  HASH_OTA_RATES,
  HASH_FULL_MAP,
  HASH_SEARCH_MAP,
  HASH_VIEWED_HOTELS,
  HASH_LOCATION_MODAL,
  HASH_HEADER_RETAIN_MODAL,
  HASH_ROOM_CHANGE_RETAIN_MODAL,
  HASH_GUESTS_ROOMS_CHILDREN,
  HASH_CHECK_IN_CHECK_OUT,
  HASH_FILTER_AND_SORT,
  HASH_RATE_DETAIL_MODAL,
  HASH_RATE_PRICE_INFO_MODAL,
];

export {
  HASH_REVIEWS,
  HASH_AMENITIES,
  HASH_GOOD_TO_KNOW,
  HASH_IMAGE_GALLERY,
  HASH_HOTELS_GALLERY,
  HASH_MAP_PORTAL,
  HASH_HOTEL_STAYDATE,
  HASH_HOTEL_STAYINFO,
  HASH_ALL_RATES,
  HASH_OTA_RATES,
  HASH_FULL_MAP,
  HASH_SEARCH_MAP,
  HASH_VIEWED_HOTELS,
  HASH_LOCATION_MODAL,
  HASH_HEADER_RETAIN_MODAL,
  HASH_ROOM_CHANGE_RETAIN_MODAL,
  HASH_GUESTS_ROOMS_CHILDREN,
  HASH_CHECK_IN_CHECK_OUT,
  HASH_FILTER_AND_SORT,
  HASH_RATE_DETAIL_MODAL,
  HASH_RATE_PRICE_INFO_MODAL,
  ROUTES,
};
