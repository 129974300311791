import { wrapDate } from '../../hotels-legacy/common/src/utils/wrap-date';

import type { SearchResultShape } from '../../hotels-legacy/client/src/components/types';
import type { Stay } from 'common-types/types/HotelStay';

export const parseStayProp = (stay: Stay): Stay => ({
  ...stay,
  checkIn: wrapDate(stay.checkIn),
  checkOut: wrapDate(stay.checkOut),
});

export const isEmptySearchResults = (
  hotelSearchResults: SearchResultShape = {},
) => {
  const { hotelCards = [], loading = true } = hotelSearchResults;

  return !loading && hotelCards.length === 0;
};
