import { differenceDaysLength } from '../../utils/machine-date-utils';

const stayLength = ({
  checkIn,
  checkOut,
}: {
  checkIn: string | Date;
  checkOut: string | Date;
}) => differenceDaysLength(checkOut, checkIn);

export default stayLength;
