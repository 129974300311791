import PropTypes from 'prop-types';

const CultureShape = PropTypes.shape({
  locale: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
});

const I18nShape = PropTypes.shape({
  translate: PropTypes.func.isRequired,
  translatePlural: PropTypes.func.isRequired,
  formatDate: PropTypes.func.isRequired,
  formatTime: PropTypes.func.isRequired,
  formatCurrency: PropTypes.func.isRequired,
  formatCurrencyFull: PropTypes.func.isRequired,
  formatNumber: PropTypes.func.isRequired,
  culture: CultureShape.isRequired,
});

export { CultureShape, I18nShape };
