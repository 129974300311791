import { Component as ReactComponent } from 'react';
import type { ComponentType, ReactNode } from 'react';

import displayName from '../../components/display-name';

import { I18nShape as LegacyI18nShape } from './i18n-shape';

import type { Optional } from 'common-types/types/components';
import type { I18n } from 'common-types/types/hotels-components/types';

interface ContextProps {
  i18n: I18n;
}

type Props = {
  value: I18n;
  children: ReactNode;
};

const ContextTypes = {
  i18n: LegacyI18nShape,
};

export class I18nProvider extends ReactComponent<Props> {
  static childContextTypes = ContextTypes;

  getChildContext() {
    return {
      i18n: this.props.value,
    };
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export const withI18n = <P extends ContextProps>(
  Component: ComponentType<P>,
): ComponentType<Optional<P, 'i18n'>> => {
  const component = (props: Optional<P, 'i18n'>, { i18n }: ContextProps) => (
    // @ts-ignore we expect that i18n can be overwritten in the unit tests
    <Component i18n={i18n} {...(props as P)} />
  );

  component.contextTypes = ContextTypes;

  component.displayName = displayName(Component);

  return component;
};

/*

The legacy context API will be removed in React 17, but we need to wait for the loss Context problem in the Portal component to be resolved

export const I18nContext = createContext<I18nShape>({});

export const withI18n = <P extends object>(Component: ComponentType<P>) => {
  const component = (props: any) => (
    <I18nContext.Consumer>
      {(context: I18nShape) => <Component i18n={context} {...(props as P)} />}
    </I18nContext.Consumer>
  );

  component.displayName = displayName(Component);

  return component;
};
*/
