import { Children } from 'react';

import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import { withI18n } from '../i18n-context';

import type { MappingEnhanceShape, Maybe } from '../../../components/types';
import type { I18n } from 'common-types/types/hotels-components/types';

type Props = {
  i18n: I18n;
  data?: MappingEnhanceShape;
  label: string;
  replacementKey?: string;
  extra?: string;
  startTag?: string;
  endTag?: string;
  cardinality?: Maybe<number>;
  maxVariants?: number;
  strong?: boolean;
  span?: boolean;
};

const FormattedMessage = ({
  cardinality = undefined,
  data = {},
  endTag = '}',
  extra = undefined,
  i18n,
  label,
  maxVariants = 9,
  replacementKey = 'number',
  span = false,
  startTag = '{',
  strong = false,
}: Props) => {
  const replacements = strong
    ? {
        strong: {
          pair: '/strong',
          format: (text: string) => (
            <BpkText textStyle={TEXT_STYLES.label1}>{text}</BpkText>
          ),
        },
        ...data,
      }
    : data;
  const nodes = cardinality
    ? i18n.translatePlural(
        label,
        cardinality,
        replacementKey,
        data,
        maxVariants,
        extra,
      )
    : i18n.translate(label, replacements, startTag, endTag);

  return Children.map(nodes, (node) => (span ? <span>{node}</span> : node));
};

export default withI18n(FormattedMessage);
