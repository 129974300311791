import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
// use date-fns/format for fallback when fail to get cldr from culture-service although it seldom happen
// eslint-disable-next-line skyscanner-dates/no-date-fns-format
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parseISO';

export const parseDate = (dateStr: string | Date) => {
  if (typeof dateStr === 'string') {
    return dateFnsParse(dateStr);
  }
  return dateStr;
};

// returns a machine-readable date ignoring locale
// this function should be only used for server side, use the one in i18n for client side
export const formatDate = (date: string | Date, format = 'yyyy-MM-dd') => {
  const definitelyDate = parseDate(date);
  return dateFnsFormat(definitelyDate, format);
};

export const differenceDaysLength = (
  fromDate: string | Date,
  toDate: string | Date,
) => {
  const length = differenceInCalendarDays(
    parseDate(fromDate),
    parseDate(toDate),
  );
  if (Number.isNaN(length)) {
    return 0;
  }
  return length;
};

export const getCurrentMonthNumDays = (
  year: string | number,
  month: string,
) => {
  let monthDay = 31;
  if (year && month) {
    switch (month) {
      case '3':
      case '5':
      case '8':
      case '10':
        monthDay = 30;
        break;
      case '1':
        if (
          (Number(year) % 4 === 0 && Number(year) % 100 !== 0) ||
          Number(year) % 400 === 0
        ) {
          monthDay = 29;
        } else {
          monthDay = 28;
        }
        break;
      default:
        monthDay = 31;
    }
  }
  return monthDay;
};
