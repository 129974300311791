import { Component as ReactComponent } from 'react';
import type { ComponentType, ReactNode } from 'react';

import displayName from '../../components/display-name';
import { FeatureTestsShape } from '../../components/shapes';

import type { FeatureTestsType } from '../types';
import type { Optional } from 'common-types/types/components';

interface ContextProps {
  featureTests: FeatureTestsType;
}

type Props = {
  featureTests: FeatureTestsType;
  children: ReactNode;
};

const ContextTypes = {
  featureTests: FeatureTestsShape,
};

class FeatureTestContext extends ReactComponent<Props> {
  static childContextTypes = ContextTypes;

  getChildContext() {
    const { featureTests } = this.props;
    return {
      featureTests,
    };
  }

  render() {
    return <> {this.props.children} </>;
  }
}

export const withFeatureTests = <P extends ContextProps>(
  Component: ComponentType<P>,
) => {
  const component = (
    props: Optional<P, 'featureTests'>,
    { featureTests }: ContextProps,
  ) => (
    // @ts-ignore we expect that featureTests can be overwritten in the unit tests
    <Component featureTests={featureTests} {...(props as P)} />
  );

  component.contextTypes = ContextTypes;

  component.displayName = displayName(Component);

  return component;
};

export default FeatureTestContext;
